<script setup lang="ts">
import {
  contentBlockBottomSpacings,
  contentBlockPaddings,
} from '@/components/ui/content-block/index.ts';

const props = withDefaults(
  defineProps<{
    padding?: keyof typeof contentBlockPaddings;
    space?: keyof typeof contentBlockBottomSpacings;
  }>(),
  {
    padding: 'default',
    space: 'default',
  },
);
</script>

<template>
  <div
    v-bind="$attrs"
    class="h-full w-full overflow-x-clip"
    :class="{
      [contentBlockPaddings[props.padding]]: true,
      [contentBlockBottomSpacings[props.space]]: true,
    }"
  >
    <slot />
  </div>
</template>
